import React, { useState } from "react";
import styles from './Slider.module.scss';
import { useTranslation } from "react-i18next";
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Fade } from "react-reveal";

import icon from '../../../assets/images/homepage/arr-right.svg';
import image1 from '../../../assets/images/homepage/slide1.webp';
import image2 from '../../../assets/images/homepage/slide2.webp';
import image3 from '../../../assets/images/homepage/slide3.webp';
import image4 from '../../../assets/images/homepage/slide4.webp';
import star1 from '../../../assets/images/homepage/star1.png';
import star2 from '../../../assets/images/homepage/star2.png';
import star3 from '../../../assets/images/homepage/star3.png';

const Slider = () => {
  const { t } = useTranslation();
  const [swiper, setSwiper] = useState({});
  const [active, setActive] = useState(0);
  const slides = t('main.seven.list', { returnObjects: true });
  const images = [image1, image2, image3, image4];
  const stars = [star1, star2, star3, star1];

  return (
      <section className={styles.wrap}>
        <div className={`${styles.container} container`}>
          <Fade left duration={2000}>
            <div className={`${styles.title} font-40-32`}>{t('main.seven.title')}</div>
          </Fade>
          <Fade right duration={2000}>
            <div className={styles.sliderWrap}>
              <Swiper
                modules={[Navigation, Pagination]}
                spaceBetween={20}
                slidesPerGroup={1}
                loop={false}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  769: {
                    slidesPerView: 1.2,
                  },
                  1024: {
                    slidesPerView: 2.2,
                  }
                }}
                onInit={(e) => { setSwiper(e) }}
                onSlideChange={(e) => setActive(e.activeIndex)}
              >
                {slides.map((item, index) => (
                  <SwiperSlide key={index} className={styles.sliderItem}>
                    <div className={styles.top}>
                      <div className={styles.imgWrap}>
                        <img src={images[index]} alt="" />
                      </div>
                      <div>
                        <div className={`${styles.slideTitle} font-16`}>{item.title}</div>
                        <div className={styles.stars}>
                          <img src={stars[index]} alt="" />
                        </div>
                      </div>
                    </div>
                    <div className={`${styles.slideText} font-14`}>{item.text}</div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </Fade>
          <div className={styles.sliderBtns}>
            <button 
              className={`${styles.sliderBtn} ${styles.sliderBtnPrev} ${active === 0 ? styles.inactive : ''}`}
              onClick={() => swiper.slidePrev()}
            >
              <img src={icon} alt="Prev" />
            </button>
            <button 
              className={`${styles.sliderBtn} ${styles.sliderBtnNext} ${active === swiper.snapGrid?.length - 1 ? styles.inactive : ''}`}
              onClick={() => swiper.slideNext()}
            >
              <img src={icon} alt="Next" />
            </button>
          </div>
        </div>
      </section>
  );
};

export default Slider;